import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  regions: {
    isLoading: false,
    data: [],
  },
  areas: {
    isLoading: false,
    data: [],
  },
};

const slice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    // SHOW LOADING REGIONS
    startLoadingRegions(state) {
      state.regions.isLoading = true;
    },

    // SHOW LOADING AREAS
    startLoadingAreas(state) {
      state.areas.isLoading = true;
    },

    // ERROR
    hasError(state, action) {
      state.areas.isLoading = false;
      state.regions.isLoading = false;
      state.error = action.payload;
    },

    // REGIONS
    regionsSuccess(state, action) {
      state.regions.isLoading = false;
      state.regions.data = action.payload;
    },

    // AREAS
    areasSuccess(state, action) {
      state.areas.isLoading = false;
      state.areas.data = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRegions() {
  return async () => {
    dispatch(slice.actions.startLoadingRegions());
    try {
      const response = await axios.get("/qabul/region");

      dispatch(slice.actions.regionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function getAreas(id) {
  return async () => {
    dispatch(slice.actions.startLoadingAreas());
    try {
      const response = await axios.get(`/qabul/region?id=${id}`, );

      dispatch(slice.actions.areasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------
