import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  AlertTitle,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
// sections
import { LoginForm } from "../../sections/auth/login";
import { Telegram } from "@mui/icons-material";

// ----------------------------------------------------------------------

const data = [
  {
    name: "Akusherlik va ginekologiya",
    all: 30,
    allG: 0,
    allK: 30,
    freeK: 26,
    targetG: 0,
    targetK: 4,
    bukharaG: 0,
    bukharaK: 1,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 1,
  },
  {
    name: "Allergologiya va klinik immunologiya",
    all: 5,
    allG: 0,
    allK: 5,
    freeK: 4,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Anesteziologiya va reanimatologiya",
    all: 20,
    allG: 0,
    allK: 20,
    freeK: 16,
    targetG: 0,
    targetK: 4,
    bukharaG: 0,
    bukharaK: 1,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 1,
  },
  {
    name: "Bolalar anesteziologiya va reanimotologiyasi",
    all: 10,
    allG: 0,
    allK: 10,
    freeK: 6,
    targetG: 0,
    targetK: 4,
    bukharaG: 0,
    bukharaK: 1,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 1,
  },
  {
    name: "Bolalar va o‘smir qizlar ginekologiyasi",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 2,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Stomatologiya yunalishlari buyicha",
    all: 14,
    allG: 0,
    allK: 14,
    freeK: 8,
    targetG: 0,
    targetK: 6,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 2,
    navoiG: 0,
    navoiK: 4,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Bolalar nevrologiyasi",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 2,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Bolalar nefrologiyasi gemodializ bilan",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 2,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Bolalar terapevtik stomatologiyasi",
    all: 7,
    allG: 0,
    allK: 7,
    freeK: 6,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Bolalar xirurgiyasi",
    all: 5,
    allG: 0,
    allK: 5,
    freeK: 4,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Bolalar yuqumli kasalliklari",
    all: 5,
    allG: 1,
    allK: 4,
    freeK: 4,
    targetG: 1,
    targetK: 0,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Gematologiya va transfuziologiya",
    all: 10,
    allG: 0,
    allK: 10,
    freeK: 7,
    targetG: 0,
    targetK: 3,
    bukharaG: 0,
    bukharaK: 1,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Dermatovenerologiya (teri tanosil kasalliklari)",
    all: 5,
    allG: 0,
    allK: 5,
    freeK: 4,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Dietologiya",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 2,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Ichki kasalliklar (terapiya) (yo‘nalishlar bo‘yicha)",
    all: 20,
    allG: 2,
    allK: 18,
    freeK: 11,
    targetG: 2,
    targetK: 7,
    bukharaG: 1,
    bukharaK: 2,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 1,
    navoiK: 2,
    suxronG: 0,
    suxronK: 2,
  },
  {
    name: "Kardiologiya",
    all: 6,
    allG: 0,
    allK: 6,
    freeK: 5,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Kardioxirurgiya",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 3,
    targetG: 0,
    targetK: 0,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Komunal gigiena",
    all: 2,
    allG: 1,
    allK: 1,
    freeK: 0,
    targetG: 1,
    targetK: 1,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Klinik farmakologiya",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 2,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Laboratoriya ishi",
    all: 18,
    allG: 4,
    allK: 14,
    freeK: 14,
    targetG: 4,
    targetK: 0,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 1,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 1,
    suxronK: 0,
  },
  {
    name: "Mexnat gigienasi",
    all: 2,
    allG: 1,
    allK: 1,
    freeK: 0,
    targetG: 1,
    targetK: 1,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Narkologiya",
    all: 2,
    allG: 2,
    allK: 0,
    freeK: 0,
    targetG: 2,
    targetK: 0,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 1,
    suxronK: 0,
  },
  {
    name: "Nevrologiya (asab kasalliklari)",
    all: 6,
    allG: 1,
    allK: 5,
    freeK: 4,
    targetG: 1,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Neyroxirurgiya",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 3,
    targetG: 0,
    targetK: 0,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Nefrologiya gemodializ bilan",
    all: 7,
    allG: 0,
    allK: 7,
    freeK: 6,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Nutritsiologiya (ovkatlanish gigienasi)",
    all: 2,
    allG: 1,
    allK: 1,
    freeK: 0,
    targetG: 1,
    targetK: 1,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Ortodontiya",
    all: 4,
    allG: 0,
    allK: 4,
    freeK: 3,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Ortopedik stomatologiya",
    all: 4,
    allG: 0,
    allK: 4,
    freeK: 3,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Otorinolaringologiya",
    all: 6,
    allG: 0,
    allK: 6,
    freeK: 4,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Oftalmologiya",
    all: 5,
    allG: 0,
    allK: 5,
    freeK: 4,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Patalogik anatomiya",
    all: 2,
    allG: 2,
    allK: 0,
    freeK: 0,
    targetG: 2,
    targetK: 0,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Pediatriya (yo‘nalishlar bo‘yicha)",
    all: 16,
    allG: 2,
    allK: 14,
    freeK: 12,
    targetG: 2,
    targetK: 2,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 1,
  },
  {
    name: "Plastik xirurgiya",
    all: 3,
    allG: 0,
    allK: 3,
    freeK: 3,
    targetG: 0,
    targetK: 0,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Psixiatriya",
    all: 6,
    allG: 3,
    allK: 3,
    freeK: 3,
    targetG: 3,
    targetK: 0,
    bukharaG: 2,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Pulmonologiya",
    all: 5,
    allG: 2,
    allK: 3,
    freeK: 3,
    targetG: 2,
    targetK: 0,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 1,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Revmatologiya",
    all: 5,
    allG: 0,
    allK: 5,
    freeK: 4,
    targetG: 0,
    targetK: 1,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Sport tibbiyoti",
    all: 2,
    allG: 2,
    allK: 0,
    freeK: 0,
    targetG: 2,
    targetK: 0,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Sud tibbiyot ekspertizasi",
    all: 4,
    allG: 2,
    allK: 2,
    freeK: 2,
    targetG: 2,
    targetK: 0,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Terapevtik stomatologiya",
    all: 10,
    allG: 0,
    allK: 10,
    freeK: 8,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Tibbiy psixologiya",
    all: 3,
    allG: 1,
    allK: 2,
    freeK: 2,
    targetG: 1,
    targetK: 0,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Tibbiy radiologiya",
    all: 14,
    allG: 0,
    allK: 14,
    freeK: 10,
    targetG: 0,
    targetK: 4,
    bukharaG: 0,
    bukharaK: 1,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 1,
  },
  {
    name: "Travmatologiya va ortopediya",
    all: 10,
    allG: 0,
    allK: 10,
    freeK: 8,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Umumiy onkologiya",
    all: 6,
    allG: 0,
    allK: 6,
    freeK: 4,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 1,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Umumiy xirurgiya",
    all: 14,
    allG: 0,
    allK: 14,
    freeK: 10,
    targetG: 0,
    targetK: 4,
    bukharaG: 0,
    bukharaK: 1,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 2,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Urologiya",
    all: 6,
    allG: 0,
    allK: 6,
    freeK: 4,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Ftiziatriya",
    all: 4,
    allG: 2,
    allK: 2,
    freeK: 2,
    targetG: 2,
    targetK: 0,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Xirurgik stomatologiya (og‘iz bo‘shlig‘i)",
    all: 4,
    allG: 0,
    allK: 4,
    freeK: 2,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Shoshilinch tibbiy yordam",
    all: 10,
    allG: 0,
    allK: 10,
    freeK: 8,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Endokrinologiya",
    all: 10,
    allG: 0,
    allK: 10,
    freeK: 8,
    targetG: 0,
    targetK: 2,
    bukharaG: 0,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 1,
    navoiG: 0,
    navoiK: 1,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Epidemiologiya",
    all: 2,
    allG: 2,
    allK: 0,
    freeK: 0,
    targetG: 2,
    targetK: 0,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 0,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
  {
    name: "Yuqumli kasalliklar",
    all: 10,
    allG: 3,
    allK: 7,
    freeK: 7,
    targetG: 3,
    targetK: 0,
    bukharaG: 1,
    bukharaK: 0,
    kashkadaryoG: 1,
    kashkadaryoK: 0,
    navoiG: 1,
    navoiK: 0,
    suxronG: 0,
    suxronK: 0,
  },
];

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
  padding: theme.spacing(4, 4, 4, 4),
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  border: "1px solid black", // Add this line
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Logo />
            <Box>
              <Typography variant="h5">
                Buxoro davlat tibbiyot instituti
              </Typography>
              <Typography variant="caption">
                Klinik-Ordinatura qabul tizimi
              </Typography>
            </Box>
          </Stack>

          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Hali ro'yxatdan o'tmaganmisiz? {""}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Ro'yxatdan o'ting
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle sx={{ textAlign: "center", mt: 10 }}>
            <Typography variant="h2">Xush kelibsiz!</Typography>
            <Typography variant="h3">Klinik-Ordinatura Qabul 2024</Typography>
            <Typography variant="h6">
              Buxoro davlat tibbiyot institutida 2024/2025 o‘quv yili uchun
              klinik ordinaturaga qabul qilish kvotasi
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 600, // Set your desired maxHeight here
                overflow: "auto",
                mt: 1,
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">T/r</TableCell>
                    <TableCell align="center">Mutaxassisliklar</TableCell>
                    <TableCell align="center">Jami:</TableCell>
                    <TableCell align="center">Erkin o‘rinlar*</TableCell>
                    <TableCell align="center">Maqsadli o‘rinlar:</TableCell>
                    <TableCell align="center">Shu jumladan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellStyled align="center"></TableCellStyled>
                    <TableCellStyled align="center"></TableCellStyled>
                    <TableCellStyled align="center"></TableCellStyled>
                    <TableCellStyled align="center"></TableCellStyled>
                    <TableCellStyled align="center"></TableCellStyled>
                    <TableCellStyled align="center">
                      <TableRow>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          Buxoro viloyati
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          Qashqadaryo viloyati
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          Navoiy viloyati
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          Surxondaryo viloyati
                        </TableCell>
                      </TableRow>
                    </TableCellStyled>
                  </TableRow>
                  <TableRow>
                    <TableCellStyled align="center"></TableCellStyled>
                    <TableCellStyled align="center"></TableCellStyled>
                    <TableCellStyled align="center">
                      <TableRow>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          jami
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          G
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          K
                        </TableCell>
                      </TableRow>
                    </TableCellStyled>
                    <TableCellStyled sx={{ fontWeight: 900 }} align="center">
                      K
                    </TableCellStyled>
                    <TableCellStyled sx={{ fontWeight: 900 }} align="center">
                      <TableRow>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          G
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          K
                        </TableCell>
                      </TableRow>
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      <TableRow>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              G
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              K
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              G
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              K
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              G
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              K
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              G
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              K
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>
                    </TableCellStyled>
                  </TableRow>
                  {data?.map((cell, idx) => (
                    <TableRow>
                      <TableCellStyled align="center">
                        {idx + 1}.
                      </TableCellStyled>
                      <TableCellStyled align="center">
                        {cell.name}
                      </TableCellStyled>
                      <TableCellStyled align="center">
                        <TableRow>
                          <TableCell align="center">{cell.all}</TableCell>
                          <TableCell align="center">{cell.allG}</TableCell>
                          <TableCell align="center">{cell.allK}</TableCell>
                        </TableRow>
                      </TableCellStyled>
                      <TableCellStyled align="center">
                        {cell.freeK}
                      </TableCellStyled>
                      <TableCellStyled align="center">
                        <TableRow>
                          <TableCell align="center">{cell.targetG}</TableCell>
                          <TableCell align="center">{cell.targetK}</TableCell>
                        </TableRow>
                      </TableCellStyled>
                      <TableCellStyled align="center">
                        <TableRow>
                          <TableCell align="center">
                            <TableRow>
                              <TableCell align="center">
                                {cell.bukharaG}
                              </TableCell>
                              <TableCell align="center">
                                {cell.bukharaK}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell align="center">
                            <TableRow>
                              <TableCell align="center">
                                {cell.kashkadaryoG}
                              </TableCell>
                              <TableCell align="center">
                                {cell.kashkadaryoK}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell align="center">
                            <TableRow>
                              <TableCell align="center">
                                {cell.navoiG}
                              </TableCell>
                              <TableCell align="center">
                                {cell.navoiK}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell align="center">
                            <TableRow>
                              <TableCell align="center">
                                {cell.suxronG}
                              </TableCell>
                              <TableCell align="center">
                                {cell.suxronK}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                        </TableRow>
                      </TableCellStyled>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCellStyled
                      align="center"
                      colSpan={2}
                    ></TableCellStyled>
                    <TableCellStyled align="center">
                      <TableRow>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          {362}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          {34}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          {330}
                        </TableCell>
                      </TableRow>
                    </TableCellStyled>
                    <TableCellStyled sx={{ fontWeight: 900 }} align="center">
                      {261}
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      <TableRow>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          {31}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center">
                          {72}
                        </TableCell>
                      </TableRow>
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      <TableRow>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {14}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {8}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {3}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {18}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {15}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {40}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell align="center">
                          <TableRow>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {2}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 900 }} align="center">
                              {7}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>
                    </TableCellStyled>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Image
           visibleByDefault
           disabledEffect
           alt="login"
           src="https://minimal-assets-api.vercel.app/assets/illustrations/illustration_login.png"
         /> */}
          </SectionStyle>
        )}

        <Container sx={{ width: "auto", mt: 3 }}>
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Alert severity="error" sx={{ mb: 3 }}>
                  <AlertTitle>❗️DIQQAT!</AlertTitle>
                  Xurmatli klinik ordinaturaga imtihon topshiradigan
                  talabgorlar. Test imtihonlari 24 avgust ertalab 7.00 da
                  o'zingiz belgilagan manzilda bo'lib o'tadi. Buxoro xududini
                  belgilagan talabgorlar uchun Buxoro davlat tibbiyot instituti
                  bosh binosi, 1 qavatda o'tkaziladi. Test imtihoniga shaxsni
                  tasdiqlovchi hujjat va ruxsatnoma bilan kelishingiz so'raladi.
                  Ruxsatnomatni{" "}
                  <a href="https://tmbm.ssv.uz/page/view/exam_permit">
                    quyidagi havola
                  </a>{" "}
                  orqali kirib olasiz!
                </Alert>
                <Alert severity="info" sx={{ mb: 3 }}>
                  Klinik ordinaturaga hujjat topshiruvchilar va ularning
                  ota-onalari uchun murojaatlar markazi (“Call markaz”) tarkibi
                  <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                    1. Kazakova Nozima Nodirovna-Terapevtik stomatologiya
                    kafedrasi dosenti, DSc{" "}
                    <b>
                      <a href="tel:+998914100147">+998914100147</a>
                    </b>
                  </Typography>
                  <Typography variant="caption" component="div">
                    2. Rustamova Zulfizar Shavkatovna - 5 sektor uslubchisi{" "}
                    <b>
                      <a href="tel:+998936835354">+998936835354</a>
                    </b>
                  </Typography>
                  <Stack
                    mt={2}
                    direction="row"
                    gap={1}
                    alignItems="center"
                    component="a"
                    href="https://t.me/klinikordinaturaqabul2024"
                  >
                    <Typography variant="caption" component="div">
                      TG Kanalimizga obuna bo'ling
                    </Typography>

                    <Box
                      width={30}
                      height={30}
                      borderRadius="100%"
                      sx={{
                        background: "#2297d0",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Telegram
                        sx={{
                          color: "#fff",
                          width: 20,
                          position: "relative",
                          left: "-1px",
                        }}
                      />
                    </Box>
                  </Stack>
                </Alert>
                <Typography variant="h4" gutterBottom>
                  Tizimga kirish
                </Typography>
                {/* <Typography sx={{ color: "text.secondary" }}>
                  Enter your details below.
                </Typography> */}
              </Box>

              {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip> */}
            </Stack>

            {/* <Alert severity="info" sx={{ mb: 3 }}>
              Введите логин и пароль для входа в личный кабинет
            </Alert> */}

            <LoginForm />

            {/* {!smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                Hali ro'yxatdan o'tmaganmisiz? {""}
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                >
                  Ro'yxatdan o'ting
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
