import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// @mui
import { Box, Divider, Stack } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import {
  addRecipients,
  onSendMessage,
  getConversation,
  getParticipants,
  markConversationAsRead,
  resetActiveConversation,
} from "../../../redux/slices/chat";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
//
import ChatRoom from "./ChatRoom";
import ChatMessageList from "./ChatMessageList";
import ChatHeaderDetail from "./ChatHeaderDetail";
import ChatMessageInput from "./ChatMessageInput";
import ChatHeaderCompose from "./ChatHeaderCompose";

// ----------------------------------------------------------------------

// const conversationSelector = (state) => {
//   const { conversations, activeConversationId } = state.chat;
//   const conversation = activeConversationId
//     ? conversations.byId[activeConversationId]
//     : null;
//   if (conversation) {
//     return conversation;
//   }
//   const initState = {
//     id: "",
//     messages: [],
//     participants: [],
//     unreadCount: 0,
//     type: "",
//   };
//   return initState;
// };

export default function ChatWindow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { conversationKey } = useParams();
  const { conversations, activeChatUserId } = useSelector(
    (state) => state.chatCallback
  );
  // const conversation = useSelector((state) => conversationSelector(state));

  const mode = conversationKey ? "DETAIL" : "COMPOSE";
  // const displayParticipants = participants.filter((item) => item.id !== '8864c717-587d-472a-929a-8e5f298024da-0');

  // useEffect(() => {
  //   const getDetails = async () => {
  //     dispatch(getParticipants(conversationKey));
  //     try {
  //       await dispatch(getConversation(conversationKey));
  //     } catch (error) {
  //       console.error(error);
  //       navigate(PATH_DASHBOARD.chat.new);
  //     }
  //   };
  //   if (conversationKey) {
  //     getDetails();
  //   } else if (activeConversationId) {
  //     dispatch(resetActiveConversation());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [conversationKey]);

  // useEffect(() => {
  //   if (activeConversationId) {
  //     dispatch(markConversationAsRead(activeConversationId));
  //   }
  // }, [dispatch, activeConversationId]);

  // const handleAddRecipients = (recipients) => {
  //   dispatch(addRecipients(recipients));
  // };

  // const handleSendMessage = async (value) => {
  //   try {
  //     dispatch(onSendMessage(value));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <Stack sx={{ flexGrow: 1, minWidth: "1px" }}>
      {/* {mode === 'DETAIL' ? (
        <ChatHeaderDetail participants={displayParticipants} />
      ) : (
        <ChatHeaderCompose
          recipients={recipients}
          contacts={Object.values(contacts.byId)}
          onAddRecipients={handleAddRecipients}
        />
      )} */}

      <Divider />

      <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden" }}>
        <Stack sx={{ flexGrow: 1 }}>
          <ChatMessageList conversations={conversations.data} />

          <Divider />

          <ChatMessageInput
            conversationId={activeChatUserId}
            // onSend={handleSendMessage}
            disabled={pathname === PATH_DASHBOARD.chat.new}
          />
        </Stack>

        {/* {mode === 'DETAIL' && <ChatRoom conversation={conversation} participants={displayParticipants} />} */}
      </Box>
    </Stack>
  );
}
