import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  isLoading: false,
  data: null,
  major: {
    isLoading: false,
    data: [],
  },
  examAreas: {
    isLoading: false,
    data: [],
  },
  statistics: {
    isLoading: false,
    data: [],
  },
};

const slice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    // SHOW LOADING REGIONS
    startLoadingUserData(state) {
      state.isLoading = true;
    },

    // SHOW LOADING Statistics
    startLoadingStatisticData(state) {
      state.statistics.isLoading = true;
    },

    // SHOW LOADING AREAS
    startLoadingMajor(state) {
      state.major.isLoading = true;
    },

    // SHOW LOADING EXAM AREAS
    startLoadingExamAreas(state) {
      state.examAreas.isLoading = true;
    },

    // ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.major.isLoading = false;
      state.examAreas.isLoading = false;
      state.error = action.payload;
    },

    // USER DATA
    userDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },

    // STATISTIC DATA
    statisticDataSuccess(state, action) {
      state.statistics.isLoading = false;
      state.statistics.data = action.payload;
    },

    // USER DATA
    setDataSuccess(state, action) {
      state.data[action.payload.docType] = action.payload;
    },

    // MAJORS
    majorsSuccess(state, action) {
      state.major.isLoading = false;
      state.major.data = action.payload;
    },

    // EXAM AREAS
    examAreasSuccess(state, action) {
      state.examAreas.isLoading = false;
      state.examAreas.data = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUserData() {
  return async () => {
    dispatch(slice.actions.startLoadingUserData());
    try {
      const response = await axios.get("/qabul/student");

      dispatch(slice.actions.userDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function saveUserData(data, cb) {
  return async () => {
    dispatch(slice.actions.startLoadingUserData());
    try {
      const response = await axios.patch("/qabul/student", data);

      dispatch(slice.actions.userDataSuccess(response.data[0]));
      cb();
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function getMajors() {
  return async () => {
    dispatch(slice.actions.startLoadingMajor());
    try {
      const response = await axios.get(`/qabul/major`);

      dispatch(slice.actions.majorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

export function getExamAreas() {
  return async () => {
    dispatch(slice.actions.startLoadingExamAreas());
    try {
      const response = await axios.get(`/qabul/exam-area`);

      dispatch(slice.actions.examAreasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function setDocumentData(docType, data) {
  return async () => {
    dispatch(slice.actions.setDataSuccess({ docType, ...data }));
  };
}

// ----------------------------------------------------------------------
export function getStatistics() {
  return async () => {
    dispatch(slice.actions.startLoadingStatisticData());
    try {
      const response = await axios.get(`/qabul/manager/student_daily`);

      dispatch(slice.actions.statisticDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}
