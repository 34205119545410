function getFileSize(file) {
  const fileSizeInBytes = typeof file == "number" ? file : file.size;
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (fileSizeInBytes >= gigabyte) {
    return (fileSizeInBytes / gigabyte).toFixed(2) + " GB";
  } else if (fileSizeInBytes >= megabyte) {
    return (fileSizeInBytes / megabyte).toFixed(2) + " MB";
  } else if (fileSizeInBytes >= kilobyte) {
    return (fileSizeInBytes / kilobyte).toFixed(2) + " KB";
  } else {
    return fileSizeInBytes + " bytes";
  }
}

export default getFileSize;
