import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  confirmLoading: false,
  unconfirmLoading: false,
  inbox: {
    loading: false,
    data: null,
  },

  selected: null,
};

const slice = createSlice({
  name: "statement",
  initialState,
  reducers: {
    // SHOW LOADING REGIONS
    startLoadingInbox(state) {
      state.inbox.loading = true;
    },
    // startLoadingConsidered(state) {
    //   state.considered.loading = true;
    // },
    // startLoadingAccepted(state) {
    //   state.accepted.loading = true;
    // },
    startLoadingConfirm(state) {
      state.confirmLoading = true;
    },
    startLoadingUnconfirm(state) {
      state.unconfirmLoading = true;
    },

    // ERROR
    hasError(state, action) {
      state.inbox.loading = false;
      // state.considered.loading = false;
      // state.accepted.loading = false;
      state.confirmLoading = true;
      state.unconfirmLoading = true;
      state.error = action.payload;
    },

    // STATEMENTS DATA
    inboxDataSuccess(state, action) {
      state.inbox.loading = false;
      state.inbox.data = action.payload;
    },
    // consideredDataSuccess(state, action) {
    //   state.considered.loading = false;
    //   state.considered.data = action.payload;
    // },
    // acceptedDataSuccess(state, action) {
    //   state.accepted.loading = false;
    //   state.accepted.data = action.payload;
    // },

    setSelectedStatementSuccess(state, action) {
      state.selected = action.payload;
    },

    // CONFIRM & UNCONFIRM DOCUMENT
    confirmDocumentSuccess(state, action) {
      state.confirmLoading = false;
      const findedIdx = state.inbox.data.results.findIndex(
        (user) => user.user.id == action.payload.userId
      );
      

      state.inbox.data.results[findedIdx][action.payload.docType] = {
        ...state.inbox.data.results[findedIdx][action.payload.docType],
        status: true,
      };

      state.selected[action.payload.docType].status = true;
    },

    unconfirmDocuemntSuccess(state, action) {
      state.unconfirmLoading = false;
      const findedIdx = state.inbox.data.results.findIndex(
        (user) => user.user.id == action.payload.userId
      );

      state.inbox.data.results[findedIdx][action.payload.docType] = {
        ...state.inbox.data.results[findedIdx][action.payload.docType],
        status: false,
      };

      state.selected[action.payload.docType].status = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInboxData(page, filterMajor = "all") {
  const faculty = filterMajor == "all" ? "" : `&major=${filterMajor}`;

  return async () => {
    dispatch(slice.actions.startLoadingInbox());
    try {
      const response = await axios.get(
        `/qabul/manager/get_some?status=1&page=${page}${faculty}`
      );

      dispatch(slice.actions.inboxDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function getConsideredData(page, filterMajor = "all") {
  const faculty = filterMajor == "all" ? "" : `&major=${filterMajor}`;

  return async () => {
    dispatch(slice.actions.startLoadingInbox());
    try {
      const response = await axios.get(
        `/qabul/manager/get_some?status=2&page=${page}${faculty}`
      );

      dispatch(slice.actions.inboxDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function getAcceptedData(page, filterMajor = "all") {
  const faculty = filterMajor == "all" ? "" : `&major=${filterMajor}`;
  return async () => {
    dispatch(slice.actions.startLoadingInbox());
    try {
      const response = await axios.get(
        `/qabul/manager/get_some?status=3&page=${page}${faculty}`
      );

      dispatch(slice.actions.inboxDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}
// ----------------------------------------------------------------------

export function confirmDocument(userId, docType, cb) {
  return async () => {
    dispatch(slice.actions.startLoadingConfirm());
    try {
      await axios.patch(`qabul/manager/verify_file?id=${userId}`, {
        file_name: docType + "_status",
      });

      dispatch(slice.actions.confirmDocumentSuccess({ userId, docType }));

      cb();
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
} // ----------------------------------------------------------------------

export function unconfirmDocument(userId, docType, message, cb) {
  return async () => {
    dispatch(slice.actions.startLoadingUnconfirm());
    try {
      const response = await axios.patch(
        `qabul/manager/unverify_file?id=${userId}`,
        { file_name: docType + "_status", message }
      );

      dispatch(slice.actions.unconfirmDocuemntSuccess({ userId, docType }));
      cb();
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}
// ----------------------------------------------------------------------

export function setSelectedStatement(data) {
  return async () => {
    try {
      dispatch(slice.actions.setSelectedStatementSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}
