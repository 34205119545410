import { paramCase } from "change-case";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useTabs from "../../hooks/useTabs";
import useSettings from "../../hooks/useSettings";
import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// _mock_
import { _userList } from "../../_mock";
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from "../../components/table";
// sections
import {
  UserTableToolbar,
  UserTableRow,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import { getAcceptedData } from "src/redux/slices/statement";
import UserInfoDrawer from "src/components/UserInfoDrawer";
import LoadingPage from "src/components/LoadingPage";
import { getMajors } from "src/redux/slices/userData";

// ----------------------------------------------------------------------

const ROLE_OPTIONS = [
  "all",
  "ux designer",
  "full stack designer",
  "backend developer",
  "project manager",
  "leader",
  "ui designer",
  "ui/ux designer",
  "front end developer",
  "full stack developer",
];

const TABLE_HEAD = [
  { id: "name", label: "F.I.SH", align: "left" },
  { id: "phone", label: "Telefon raqami", align: "left" },
  { id: "email", label: "Elektron manzili", align: "left" },
  { id: "examArea", label: "Test topshiradgian hududi", align: "center" , minWidth: 150},
  { id: "yunalish", label: "Yo'nalishi", align: "center" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function Accepted() {
  const {
    dense,
    // page,
    order,
    orderBy,
    rowsPerPage,
    // setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    // onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState(_userList);

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("all");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [page, setPage] = useState(0);

  const { inbox } = useSelector((state) => state.statement);
  const { major } = useSelector((state) => state.userData);

  const dispatch = useDispatch();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs("all");

  useEffect(() => {
    dispatch(getAcceptedData(page + 1, filterRole));
  }, [page, filterRole]);

  useEffect(() => {
    dispatch(getMajors());
  }, []);

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openDrawer = () => {
    setIsOpenDrawer(true);
  };

  const closeDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !inbox.data?.results?.length;

  return (
    <Page title="Arizalar: Qabul qilinganlar">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Qabul qilingan arizalar"
          links={[{ name: "", href: PATH_DASHBOARD.root }]}
          // action={
          //   <Button
          //     variant="contained"
          //     component={RouterLink}
          //     to={PATH_DASHBOARD.user.new}
          //     startIcon={<Iconify icon={"eva:plus-fill"} />}
          //   >
          //     New User
          //   </Button>
          // }
        />
        <UserTableToolbar
          filterRole={filterRole}
          onFilterRole={handleFilterRole}
          optionsRole={major.data}
        />

        <Card sx={{ position: "relative", height: "60vh" }}>
          {inbox.loading ? (
            <LoadingPage />
          ) : (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: "relative" }}>
                  {selected.length > 0 && (
                    <TableSelectedActions
                      numSelected={selected.length}
                      rowCount={tableData.length}
                      actions={
                        <Tooltip title="O'chirish">
                          <IconButton
                            color="primary"
                            onClick={() => handleDeleteRows(selected)}
                          >
                            <Iconify icon={"eva:trash-2-outline"} />
                          </IconButton>
                        </Tooltip>
                      }
                    />
                  )}

                  <Table size="small">
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />

                    <TableBody>
                      {inbox.data?.results.map((row, idx) => (
                        <UserTableRow
                          key={idx}
                          row={row}
                          onSelectRow={openDrawer}
                        />
                      ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: "relative" }}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
            /> */}
              </Box>
            </>
          )}
        </Card>
        <Box sx={{ position: "relative" }}>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={inbox.data?.total}
            rowsPerPage={10}
            page={page}
            onPageChange={onChangePage}
            // onRowsPerPageChange={onChangeRowsPerPage}
          />
          {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
            /> */}
        </Box>
      </Container>
      {isOpenDrawer && (
        <UserInfoDrawer open={isOpenDrawer} onClose={closeDrawer} />
      )}
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== "all") {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== "all") {
    tableData = tableData.filter((item) => item.role === filterRole);
  }

  return tableData;
}
