import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Typography } from "@mui/material";
import Iconify from "./Iconify";
import { useDispatch, useSelector } from "react-redux";
import { unconfirmDocument } from "src/redux/slices/statement";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import { LoadingButton } from "@mui/lab";

export default function ConfirmIncorrectDocumentDialog({
  userId,
  docType,
  open,
  onClose,
  fileName,
}) {
  const [text, setText] = React.useState("");

  const { unconfirmLoading } = useSelector((state) => state.statement);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => setText(e.target.value);

  const onSend = () => {
    dispatch(
      unconfirmDocument(userId, docType, text, () => {
        onClose();
        enqueueSnackbar("Hujjat bekor qilindi!");
      })
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Hujjatning kamchiliklari bormi?{" "}
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              {" "}
              Fayl:{" "}
              <Typography component="span" color="#1890FF">
                {fileName}
              </Typography>{" "}
            </Typography>
            <TextField
              error={!text}
              value={text}
              onChange={handleChange}
              sx={{ mt: 1 }}
              rows={5}
              fullWidth
              multiline
              placeholder="Kamchiliklarni yozing"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="info">
            Yopish
          </Button>
          <LoadingButton
            onClick={onSend}
            autoFocus
            disabled={!text}
            loading={unconfirmLoading}
          >
            Yuborish{" "}
            <Iconify sx={{ ml: 0.5 }} icon={"material-symbols:send"} />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
