import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Typography } from "@mui/material";
import Iconify from "./Iconify";

export default function SendMessageDialog({ open, onClose, userId }) {
  const [text, setText] = React.useState("");

  const handleChange = (e) => setText(e.target.value);

  const onSend = () => {};

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Новое сообщение </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText id="alert-dialog-description">
            <TextField
              error={!text}
              value={text}
              onChange={handleChange}
              sx={{ mt: 1 }}
              rows={5}
              fullWidth
              multiline
              placeholder="Введите текст сообщения"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="info">
            Закрыть
          </Button>
          <Button onClick={onClose} autoFocus disabled={!text}>
            Отправить{" "}
            <Iconify sx={{ ml: 0.5 }} icon={"material-symbols:send"} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
