import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import { uz } from "date-fns/locale";
// @mui
import { styled } from "@mui/material/styles";
import { Avatar, Box, Typography } from "@mui/material";
// components
import Image from "../../../components/Image";
import { useSelector } from "react-redux";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  // conversation: PropTypes.object.isRequired,
  // onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({ message }) {
  // const sender = conversation.participants.find((participant) => participant.id === message.senderId);
  const { user } = useAuth();

  const senderDetails =
    message.receiver != user?.id
      ? { type: "me" }
      : {
          avatar: message?.sender?.photo,
          name: `${message?.sender?.middle_name} ${message?.sender?.name} ${message?.sender?.last_name}`,
        };

  const isMe = senderDetails.type === "me";

  const isImage = message.contentType === "image";
  const fullName = senderDetails.name;

  return (
    <RootStyle>
      <Box
        sx={{
          display: "flex",
          ...(isMe && {
            ml: "auto",
          }),
        }}
      >
        {senderDetails.type !== "me" && (
          <Avatar
            alt={senderDetails.name}
            src={senderDetails.avatar}
            sx={{ width: 32, height: 32, mr: 2 }}
          />
        )}

        <div>
          <InfoStyle
            variant="caption"
            sx={{
              ...(isMe && { justifyContent: "flex-end" }),
            }}
          >
            {!isMe && `${fullName},`}&nbsp;
            {formatDistanceToNowStrict(new Date(message?.created_at), {
              addSuffix: true,
              locale: uz,
            })}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isMe && { color: "grey.800", bgcolor: "primary.lighter" }),
              ...(isImage && { p: 0 }),
            }}
          >
            {/* {isImage ? (
              <Image
                alt="attachment"
                src={message.body}
                onClick={() => onOpenLightbox(message.body)}
                sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
              />
            ) : ( */}
            <Typography variant="body2">{message?.message}</Typography>
            {/* )} */}
          </ContentStyle>
        </div>
      </Box>
    </RootStyle>
  );
}
