import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

function objFromArray(array, key = "id") {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  chats: {
    loading: false,
    data: [],
  },
  conversations: {
    loading: false,
    data: [],
  },
  activeChatUserId: null,
};

const slice = createSlice({
  name: "chatCallback",
  initialState,
  reducers: {
    // START LOADING
    startLoadingConversations(state) {
      state.conversations.loading = true;
    },

    startLoadingChats(state) {
      state.chats.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.conversations.loading = false;
      state.chats.loading = false;
      state.error = action.payload;
    },

    // SET ACTIVE CHAT ID
    setActiveChatUserId(state, action) {
      state.activeChatUserId = action.payload;
    },

    // GET CHATS SSUCCESS
    getChatsSuccess(state, action) {
      const chats = action.payload;

      state.chats.loading = false;
      state.chats.data = chats;
    },

    // SET MESSAGE SSUCCESS
    setMessageSuccess(state, action) {
      state.conversations.loading = false;

      state.conversations.data.push(action.payload);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.data = conversations;
      state.conversations.loading = false;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveChatUserId(state) {
      state.activeChatUserId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addRecipients,
  onSendMessage,
  resetActiveConversation,
  setActiveChatUserId,
} = slice.actions;

// ----------------------------------------------------------------------

export function getChats() {
  return async () => {
    dispatch(slice.actions.startLoadingChats());
    try {
      const response = await axios.get("/qabul/manager-chat/users");
      dispatch(slice.actions.getChatsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendMessage(role = "student", data, cb) {
  return async () => {
    dispatch(slice.actions.startLoadingConversations());
    let response;

    try {
      if (role == "student") {
        response = await axios.post("/qabul/student-chat", data);
      }

      if (role == "manager") {
        response = await axios.post("/qabul/manager-chat", data);
      }

      dispatch(slice.actions.setMessageSuccess(response.data.data));
      cb();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations(role = "student", userId) {
  return async () => {
    dispatch(slice.actions.startLoadingConversations());
    try {
      let response;

      if (role == "manager") {
        response = await axios.get(`/qabul/manager-chat?student=${userId}`);
      } else {
        response = await axios.get("/qabul/student-chat");
      }

      dispatch(slice.actions.getConversationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/chat/conversation", {
        params: { conversationKey },
      });
      dispatch(
        slice.actions.getConversationSuccess(response.data.conversation)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get("/api/chat/conversation/mark-as-seen", {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/chat/participants", {
        params: { conversationKey },
      });
      dispatch(
        slice.actions.getParticipantsSuccess(response.data.participants)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
