import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { setDocumentData } from "./userData";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  isLoading: false,
};

const slice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    // SHOW LOADING DOCUMENT SAVE
    startLoadingUploadDocument(state) {
      state.isLoading = true;
    },

    // ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPLOAD DOCUMENT
    uploadDocumentSuccess(state) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function uploadDocument(data, docFieldName, cb) {
  return async () => {
    dispatch(slice.actions.startLoadingUploadDocument());
    try {
      const response = await axios.patch("/qabul/student/add_file", data);

      dispatch(slice.actions.uploadDocumentSuccess(response.data.data));
      const { type, path, status, created_at, size } = response.data.data;

      dispatch(
        setDocumentData(docFieldName, { type, path, status, size, created_at })
      );

      cb();
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки файла"));
    }
  };
}
