import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Button, Link, Container, Typography } from "@mui/material";
// layouts
import LogoOnlyLayout from "../../layouts/LogoOnlyLayout";
// routes
import { PATH_AUTH } from "../../routes/paths";
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
// sections
import { VerifyCodeForm } from "../../sections/auth/verify-code";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  alignItems: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyCode() {
  return (
    <Page title="Verify" sx={{ height: 1 }}>
      <RootStyle>
        <LogoOnlyLayout />

        <Container>
          <Box sx={{ maxWidth: 480, mx: "auto" }}>
            <Button
              size="small"
              component={RouterLink}
              to={PATH_AUTH.login}
              startIcon={
                <Iconify
                  icon={"eva:arrow-ios-back-fill"}
                  width={20}
                  height={20}
                />
              }
              sx={{ mb: 3 }}
            >
              Назад
            </Button>

            <Typography variant="h3" paragraph>
              Пожалуйста, проверьте свою эл.почту!
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Мы отправили 6-значный код подтверждения по электронной почте на
              адрес acb@domain. Введите код в поле ниже, чтобы подтверМы отправили 6-значный код подтверждения по электронной почте на адрес acb@domain. Введите код в поле ниже, чтобы подтвердить свой адрес электронной почты.backдить свой
              адрес электронной почты.
            </Typography>

            <Box sx={{ mt: 5, mb: 3 }}>
              <VerifyCodeForm />
            </Box>

            <Typography variant="body2" align="center">
              Не получили код? &nbsp;
              <Link variant="subtitle2" underline="none" onClick={() => {}}>
                Отправить код ещё раз
              </Link>
            </Typography>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
