import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// @mui
import { List } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { SkeletonConversationItem } from "../../../components/skeleton";
//
import ChatConversationItem from "./ChatConversationItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getConversations,
  setActiveChatUserId,
} from "src/redux/slices/chatCallback";
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------

ChatConversationList.propTypes = {
  conversations: PropTypes.object,
  isOpenSidebar: PropTypes.bool,
  activeConversationId: PropTypes.string,
  sx: PropTypes.object,
};

export default function ChatConversationList({
  chats,
  isOpenSidebar,
  activeConversationId,
  sx,
  ...other
}) {
  const [selectedChat, setSelectedChat] = useState(null);

  const { activeChatUserId } = useSelector((state) => state.chatCallback);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeChatUserId) {
      dispatch(getConversations("manager", activeChatUserId));
    }
  }, [activeChatUserId]);

  const handleSelectChat = (userId) => {
    dispatch(setActiveChatUserId(userId));
    // navigate(PATH_DASHBOARD.chat.view(userId));
  };

  // const loading = !conversations.allIds.length;

  return (
    <List disablePadding sx={sx} {...other}>
      {chats.loading ? (
        <>
          <SkeletonConversationItem />
          <SkeletonConversationItem />
          <SkeletonConversationItem />
          <SkeletonConversationItem />
          <SkeletonConversationItem />
          <SkeletonConversationItem />
          <SkeletonConversationItem />
          <SkeletonConversationItem />
        </>
      ) : (
        chats.data.map((chat, index) => (
          <ChatConversationItem
            key={index}
            isOpenSidebar={isOpenSidebar}
            chat={chat}
            isSelected={chat.sender.id === selectedChat}
            onSelectConversation={() => {
              setSelectedChat(chat.sender.id);
              handleSelectChat(chat.sender.id);
            }}
          />
        ))
      )}
      {}
    </List>
  );
}
