import {
  MenuItem,
  ListItemText,
  TableCell,
  TableRow,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { TableMoreMenu } from "./table";
import Iconify from "./Iconify";
import { useState } from "react";
import ConfirmDocumentDialog from "./ConfirmDocumentDialog";
import ConfirmIncorrectDocumentDialog from "./ConfirmIncorrectDocumentDialog";
import DocumentDrawer from "./DocumentDrawer";
import { useSelector } from "react-redux";
import getFileSize from "src/utils/getFileSize";
import moment from "moment";
import SendMessageDialog from "./SendMessageDialog";
import { format } from "date-fns";
import { uz } from "date-fns/locale";

const DocumentTableRow = ({ data, forRole = "admin", user }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerOptions, setDrawerOptions] = useState({});
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openIncorrectConfirmDialog, setOpenIncorrectConfirmDialog] =
    useState(false);

  const { data: userData } = useSelector((state) => state.userData);

  const openDrawer = (options) => {
    setIsOpenDrawer(true);
    setDrawerOptions(options);
  };

  const closeDrawer = () => {
    setIsOpenDrawer(false);
    setDrawerOptions({});
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleOpenIncorrectConfirmDialog = () => {
    setOpenIncorrectConfirmDialog(true);
  };

  const handleCloseIncorrectConfirmDialog = () => {
    setOpenIncorrectConfirmDialog(false);
  };

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const infoData = user || userData;

 

  return (
    <>
      <TableRow
        onClick={() => {
          openDrawer({
            errorMessage: "DOC yoki DOCX formatdagi faylni tanlang",
            allowedFileTypes: data.allowedFileTypes,
            docFieldName: data.docFieldName,
          });
        }}
        sx={{
          "&:hover": {
            backgroundColor: infoData?.[data.docFieldName]?.status
              ? "rgba(76,175,80, 0.29)"
              : infoData?.[data.docFieldName]
              ? "rgba(255,152,0,0.37)"
              : "rgba(255,0,0,0.2)",
            boxShadow: "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
          },
          cursor: "pointer",
          backgroundColor: infoData?.[data.docFieldName]?.status
            ? "rgba(76,175,80, 0.29)"
            : infoData?.[data.docFieldName]
            ? "rgba(255,152,0,0.37)"
            : "rgba(255,0,0,0.2)",
          transition:
            "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
      >
        <TableCell
          sx={{
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
            borderLeft: "1px solid rgba(145, 158, 171, 0.16)",
            borderTop: "1px solid rgba(145, 158, 171, 0.16)",
            borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
          }}
        >
          <Typography>
            {infoData?.[data.docFieldName] ? (
              <Link
                href={infoData?.[data.docFieldName]?.path}
                underline="hover"
                target="_blank"
              >
                {data.docType}
              </Link>
            ) : (
              data.docType
            )}
          </Typography>
        </TableCell>
        {/* <TableCell
          sx={{
            borderTop: "1px solid rgba(145, 158, 171, 0.16)",
            borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
          }}
        >
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <img
              height={36}
              width={36}
              src={`/icons/ic_${data.reqiredFileType}.svg`}
            />
            <Typography>
              {" "}
              {infoData?.[data.docFieldName]
                ? infoData?.[data.docFieldName].name
                : "Юкланмаган"}
            </Typography>
          </Stack>
        </TableCell> */}
        <TableCell
          sx={{
            borderTop: "1px solid rgba(145, 158, 171, 0.16)",
            borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
          }}
        >
          {" "}
          <Stack flexDirection="row" alignItems="center" gap={2}>
            {infoData?.[data.docFieldName] && (
              <img
                height={36}
                width={36}
                src={`/icons/ic_${data.reqiredFileType}.svg`}
              />
            )}
            {infoData?.[data.docFieldName]
              ? infoData?.[data.docFieldName].type.toUpperCase()
              : "Yuklanmagan"}
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            borderTop: "1px solid rgba(145, 158, 171, 0.16)",
            borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
          }}
        >
          {infoData?.[data.docFieldName]
            ? getFileSize(infoData?.[data.docFieldName].size)
            : "Yuklanmagan"}
        </TableCell>
        <TableCell
          sx={
            forRole === "user"
              ? {
                  borderTopRightRadius: "16px",
                  borderBottomRightRadius: "16px",
                  borderRight: "1px solid rgba(145, 158, 171, 0.16)",
                  borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                  borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                }
              : {
                  borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                  borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                }
          }
        >
          <ListItemText
            primary={
              infoData?.[data.docFieldName]?.created_at
                ? format(
                    new Date(infoData?.[data.docFieldName]?.created_at),
                    "dd MMM yyyy",
                    { locale: uz }
                  )
                : ""
            }
            secondary={
              infoData?.[data.docFieldName]?.created_at
                ? format(
                    new Date(infoData?.[data.docFieldName]?.created_at),
                    "HH:mm",
                    { locale: uz }
                  )
                : ""
            }
          />
        </TableCell>
        {forRole === "admin" && (
          <TableCell
            key={4}
            align="right"
            sx={{
              borderTopRightRadius: "16px",
              borderBottomRightRadius: "16px",
              borderRight: "1px solid rgba(145, 158, 171, 0.16)",
              borderTop: "1px solid rgba(145, 158, 171, 0.16)",
              borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
            }}
          >
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleOpenConfirmDialog();
                    }}
                    sx={{ color: "green" }}
                    disabled={!infoData?.[data.docFieldName]}
                  >
                    <Iconify icon={"material-symbols:check"} />
                    Qabul qilish
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpenIncorrectConfirmDialog();
                      handleCloseMenu();
                    }}
                    sx={{ color: "red" }}
                    disabled={!infoData?.[data.docFieldName]}
                  >
                    <Iconify icon={"mdi:file-cancel-outline"} />
                    Bekor qilish
                  </MenuItem>
                </>
              }
            />
          </TableCell>
        )}
      </TableRow>

      {forRole === "user" && (
        <DocumentDrawer
          open={isOpenDrawer}
          onClose={closeDrawer}
          {...drawerOptions}
        />
      )}

      <ConfirmDocumentDialog
        userId={infoData?.user?.id}
        docType={data.docFieldName}
        fileName={data.fileName}
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
      />
      <ConfirmIncorrectDocumentDialog
        userId={infoData?.user?.id}
        docType={data.docFieldName}
        fileName={data.fileName}
        open={openIncorrectConfirmDialog}
        onClose={handleCloseIncorrectConfirmDialog}
      />
    </>
  );
};

export default DocumentTableRow;
