import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import authReducer from "./slices/auth";
import regionsReducer from "./slices/regions";
import userDataReducer from "./slices/userData";
import documentReducer from "./slices/document";
import statementReducer from "./slices/statement";
import chatCallbackReducer from "./slices/chatCallback";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  auth: authReducer,
  regions: regionsReducer,
  userData: userDataReducer,
  document: documentReducer,
  statement: statementReducer,
  chatCallback: chatCallbackReducer,
});

export { rootPersistConfig, rootReducer };
