import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Login from "../pages/auth/Login";
// components
import LoadingScreen from "../components/LoadingScreen";
import VerifyCode from "src/pages/auth/VerifyCode";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const navigate = useNavigate();



  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  // if (user?.verify_status && user?.verify_status == false) {
  //   return <Navigate to={"/auth/verify"} />;
  // }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
