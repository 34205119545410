// @mui
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  ListItemText,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Table,
  Container,
} from "@mui/material";
// utils
import { fData } from "../../utils/formatNumber";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// _mock
import { countries } from "../../_mock";
// components
import Label from "../../components/Label";
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from "../../components/hook-form";
import DocumentDrawer from "src/components/DocumentDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "src/redux/slices/userData";
import DocumentTableRow from "src/components/DocumentTableRow";
import { ChatSidebar, ChatWindow } from "src/sections/@dashboard/chat";
import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useSettings from "src/hooks/useSettings";
import {
  getChats,
  getConversations,
  setActiveChatUserId,
} from "src/redux/slices/chatCallback";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

const filesData = [
  {
    reqiredFileType: "word",
    docType: "Ариза",
    fileName: "моё_заявление",
    fileType: "PDF",
    docFieldName: "application",
    allowedFileTypes: ["application/pdf"],
  },
  {
    reqiredFileType: "pdf",
    docType: "086-У-шаклидаги тиббий маълумотнома",
    fileName: "моё_заявление",
    fileType: "PDF",
    docFieldName: "document_086",
    allowedFileTypes: ["application/pdf"],
  },
  {
    reqiredFileType: "pdf",
    docType: "Олий маълумот тўғрисидаги хужжат ",
    fileName: "моё_заявление",
    fileType: "PDF",
    docFieldName: "patent",
    allowedFileTypes: ["application/pdf"],
  },
  {
    reqiredFileType: "pdf",
    docType: "",
    fileName: "моё_заявление",
    fileType: "PDF",
    docFieldName: "passport_photo",
    allowedFileTypes: ["application/pdf"],
  },
  {
    reqiredFileType: "word",
    docType: "Имтиёзлар",
    fileName: "моё_заявление",
    fileType: "PDF",
    docFieldName: "benefit",
    allowedFileTypes: ["application/pdf"],
  },
];

Callback.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function Callback({ isEdit, currentUser }) {
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [drawerOptions, setDrawerOptions] = useState({});

  const { themeStretch } = useSettings();
  const { data } = useSelector((state) => state.userData);
  const { activeChatUserId } = useSelector((state) => state.chatCallback);
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.role == 2) {
      dispatch(getChats());
    }

    if (user?.role == 3) {
      dispatch(setActiveChatUserId(2));
    }
  }, []);

  useEffect(() => {
    if (user?.role == 3 && activeChatUserId) {
      dispatch(getConversations());
    }
  }, [activeChatUserId]);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Page title="Teskari aloqa">
      <Container maxWidth={themeStretch ? false : "xl"}>
        {/* <HeaderBreadcrumbs
          heading="Teskari aloqa"
          links={[
          ]
            { name: "Chat" },
          ]}
        /> */}
        <Card sx={{ height: "72vh", display: "flex" }}>
          {user?.role == 3 ? (
            <ChatWindow />
          ) : (
            <>
              <ChatSidebar />
              <ChatWindow />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
